import { SlDefault } from '../_sl-default/sl-default';

export class SlModal extends SlDefault {
    private options = {
        slidesToShow: 1,
        dots: false,
        arrows: true,
    };

    constructor($el) {
        super($el);
        this.init(this.options);
    }
}
