import { Subject } from 'rxjs/Subject';
import { Singleton } from './helpers';

@Singleton()
export class Tabs {
    private $tabs;
    public readonly subject = new Subject<{ name: string; data: any }>();

    private init() {
        const that = this;

        this.$tabs.each(function() {
            that.addEvents.call(that, $(this));
            //that.selectTabs();
            //that.handleHash();
        });
    }

    private addEvents($el) {
        const that = this;

        $el.on('click.toggleContent', '.js-selectTab:not(._active)', function() {
            that.selectTab.call(that, $el, $(this));

            // if (this.id) {
            //     window.history.pushState(null, null, '#' + this.id);
            // }
        });
    }

    private selectTab($el, $tab) {
        var $content = $el.find('.js-selectTabContent'),
            $siblings = $tab.siblings(),
            $buttons = $el.find('.js-selectTab'),
            index = $buttons.index($tab);

        if (!$siblings.length) {
            $siblings = $buttons.filter('._active');
        }

        $tab.addClass('_active');
        $siblings.removeClass('_active');
        $content.length &&
            $content
                .removeClass('_active')
                .eq(index)
                .addClass('_active');

        this.subject.next({ name: 'tabChange', data: index });
    }

    private selectTabs() {
        let selectedTab;

        try {
            selectedTab = window.location.href.split('#')[1];
        } catch (e) {}

        if (selectedTab) {
            // $('#' + selectedTab).trigger('click.toggleContent');

            const $el = $('#' + selectedTab);
            const $parent = $el.parents('.js-tabs');

            this.selectTab($parent, $el);
        }

        console.log(selectedTab);
    }

    private handleHash() {
        $(window).on('popstate', event => {
            this.selectTabs();
        });
    }

    constructor($tabs?) {
        this.$tabs = $tabs;
        this.init();
    }
}
