export class Common {
    private static scroll($el, offset, time) {
        $el.animate(
            {
                scrollTop: offset,
            },
            time,
        );
    }

    public static scrollTo(data) {
        if (Array.isArray(data)) {
            data.forEach(item => {
                Common.scroll(item.$el, item.offset, item.time);
            });
        } else {
            Common.scroll(data.$el, data.offset, data.time);
        }
    }

    public static replaceConsole() {
        let method;
        const noop = () => {};
        const methods = [
            'assert',
            'clear',
            'count',
            'debug',
            'dir',
            'dirxml',
            'error',
            'exception',
            'group',
            'groupCollapsed',
            'groupEnd',
            'info',
            'log',
            'markTimeline',
            'profile',
            'profileEnd',
            'table',
            'time',
            'timeEnd',
            'timeStamp',
            'trace',
            'warn',
        ];
        let length = methods.length;
        let console = ((<any>window).console = window.console || {});

        while (length--) {
            method = methods[length];

            if (!console[method]) {
                console[method] = noop;
            }
        }
    }
}
