import { Singleton } from '../../../js/common/helpers';

@Singleton()
export class ModalDefault {
    private modals = [];

    constructor() {
        this.addEvents();
    }

    private unbindCloseModalByEscape() {
        return $(document).off('keyup.closeModalByEscape');
    }

    private closeModalByEscape(e) {
        if (e.keyCode === 27) {
            const length = this.modals.length;

            if (length) {
                this.close(this.modals[length - 1].element);
            }
        }
    }

    private get(element) {
        const modal = this.modals.find(function(modal) {
            return modal.element === element;
        });

        return (modal && modal.params) || {};
    }

    public open(selector, params?) {
        const deferred = $.Deferred();
        const $modal = $(selector);

        $modal.addClass('_open');

        this.modals.push({
            element: $modal[0],
            deferred: deferred,
            selector: selector,
            params: params || {},
        });

        this.unbindCloseModalByEscape().on('keyup.closeModalByEscape', this.closeModalByEscape);

        return deferred.promise();
    }

    public close(element) {
        const index = this.modals.findIndex(function(modal) {
            return modal.element === element;
        });

        if (index === -1) {
            return false;
        }

        const active = this.modals[index];

        if (active.params.prevent && active.params.prevent()) {
            return false;
        }

        this.modals.splice(index, 1);

        if (this.modals.length === 0) {
            this.unbindCloseModalByEscape();
        }

        $(active.element).removeClass('_open');
        active.deferred.resolve(true);

        return true;
    }

    private addEvents() {
        const that = this;

        $(document)
            .on('click.closeModalByOverlayClick', '.modal-default', function(e) {
                if (e.target === this) {
                    that.close(this);
                }
            })
            .on('click.closeModal', '.js-closeModal', function() {
                that.close(
                    $(this)
                        .parents('.modal-default')
                        .get(0),
                );
            });
    }
}
