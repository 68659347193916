import { SlDefaultWithModal } from '../_sl-default/sl-default-with-modal';

export class SlViews extends SlDefaultWithModal {
    private options: JQuerySlickOptions = {
        slidesToShow: 1,
        dots: true,
    };

    constructor(el: HTMLElement) {
        super(el);
        this.init(this.options);
    }
}
