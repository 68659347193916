import { Subject } from 'rxjs/Subject';
import { Singleton } from './helpers';

@Singleton()
export class Accordion {
    private $accordion;
    public readonly subject = new Subject<{ name: string; data: any }>();

    private init() {
        const that = this;

        this.$accordion.each(function() {
            that.addEvents.call(that, $(this));
        });
    }

    private addEvents($el) {
        const that = this;

        $el.on('click.toggleAccordion', '.js-toggleAccordion', function() {
            that.toggleAccordion.call(that, $(this));
        });
    }

    private toggleAccordion($el) {
        var $parent = $el.parent(),
            $list = $el.siblings(),
            classNameActive = '_active',
            classNameAnimate = '_animate';

        if ($parent.hasClass(classNameActive)) {
            $parent.addClass(classNameAnimate);

            $list.stop().slideUp(300, function() {
                $(this).removeAttr('style');
                $parent.removeClass([classNameAnimate, classNameActive].join(' '));
            });
        } else {
            $parent
                .addClass(classNameAnimate)
                .siblings('._active')
                .addClass(classNameAnimate)
                .find('.js-toggleAccordion')
                .siblings()
                .stop()
                .slideUp(300, function() {
                    $(this)
                        .removeAttr('style')
                        .parent()
                        .removeClass([classNameAnimate, classNameActive].join(' '));
                });

            $list.stop().slideDown(300, function() {
                $(this).removeAttr('style');
                $parent.removeClass(classNameAnimate).addClass(classNameActive);
            });
        }
    }

    constructor($accordion?) {
        this.$accordion = $accordion;
        this.init();
    }
}
