import { SlDefaultWithModal } from '../_sl-default/sl-default-with-modal';

export class SlPreview extends SlDefaultWithModal {
    private options: JQuerySlickOptions = {
        slidesToShow: 1,
        dots: false,
        arrows: true,
        autoplay: false,
        autoplaySpeed: 5000,
    };

    constructor(el: HTMLElement) {
        super(el);
        this.init(this.options);
    }
}
