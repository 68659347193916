declare var InstallTrigger: any;
declare var opr: any;
declare var safari: any;

class KnownClassNames {
    MAC = '_mac';
    OPERA = '_opera';
    FIREFOX = '_moz';
    SAFARI = '_safari';
    IE = '_ie';
    IE9 = '_ie9';
    IE10 = '_ie10';
    EDGE = '_edge';
    EDGE13 = '_edge-13';
    EDGE14 = '_edge-14';
    CHROME = '_chrome';
    BLINK = '_blink';
    YA = '_ya';
    IOS = '_ios';
    IPAD = '_ipad';
    IPHONE = '_iphone';
}

class KnownBrowsers {
    IS_MAC = navigator.appVersion.indexOf('Mac') !== -1;
    // Opera 8.0+
    IS_OPERA = (!!window['opr'] && !!opr.addons) || !!window['opera'] || navigator.userAgent.indexOf(' OPR/') >= 0;
    // Firefox 1.0+
    IS_FIREFOX = typeof InstallTrigger !== 'undefined';
    // At least Safari 3+ = "[object HTMLElementConstructor];
    IS_SAFARI = Object.prototype.toString.call(window['HTMLElement']).indexOf('Constructor') > 0 ||
        /constructor/i.test(window['HTMLElement']) ||
        (function (p) {
            return p.toString() === '[object SafariRemoteNotification]';
        })(!window['safari'] || safari.pushNotification);
    // Internet Explorer 6-11
    IS_IE = /*@cc_on!@*/ false || !!document['documentMode'];
    IS_IE9 = Function('/*@cc_on return document.documentMode===9@*/')();
    IS_IE10 = Function('/*@cc_on return document.documentMode===10@*/')();
    // Edge 20+
    IS_EDGE14 = navigator.userAgent.indexOf(' Edge/14') >= 0;
    IS_EDGE13 = navigator.userAgent.indexOf(' Edge/13') >= 0;
    IS_EDGE = !this.IS_IE && !!window['StyleMedia'];
    // Chrome 1+
    IS_CHROME = !!window['chrome'] && !!window['chrome'].webstore;
    // Blink engine detection
    IS_BLINK = (this.IS_CHROME || this.IS_OPERA) && !!window['CSS'];
    IS_YA = navigator.userAgent.indexOf(' YaBrowser/') >= 0;

    IS_IPAD = window.navigator.userAgent.toLowerCase().indexOf('ipad') !== -1;
    IS_IPHONE = !(window.navigator.userAgent.toLowerCase().indexOf('windows') !== -1) &&
        window.navigator.userAgent.toLowerCase().indexOf('iphone') !== -1;
}

export class DetectBrowser {
    private knownBrowsers = new KnownBrowsers();
    private knownClassNames = new KnownClassNames();

    constructor() {
        this.detect();
    }

    public detect() {
        const classNames: string[] = [];

        Object.keys(this.knownBrowsers).forEach(browser => {
            this.knownBrowsers[browser] && classNames.push(this.knownClassNames[browser.slice(3, browser.length)]);
        });

        this.addClassName(classNames);
    }

    private addClassName(classNames: string[]) {
        const html = document.querySelector('html');

        html.classList
            ? html.classList.add(...classNames)
            : html.className += classNames.join(' ');
    }
}
