export function Singleton() {
    let instance = null;
    return function(target) {
        const original = target;

        const f: any = function(...args) {
            if (!instance) {
                instance = new original(...args);
            }

            return instance;
        };

        f.prototype = original.prototype;

        return f;
    };
}
