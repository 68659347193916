import { Tabs } from '../../../js/common/tabs';
import { filter } from 'rxjs/operators';
import { SlDefaultWithModal } from '../_sl-default/sl-default-with-modal';

export class SlFeatures extends SlDefaultWithModal {
    private options: JQuerySlickOptions = {
        slidesToShow: 1,
        arrows: true,
        autoplay: false,
        autoplaySpeed: 5000,
    };

    private tabs = new Tabs();

    constructor(el: HTMLElement) {
        super(el);
        this.init(this.options);

        this.tabs.subject.pipe(filter(({ name }) => name === 'tabChange')).subscribe(_ => this.setPosition());
    }
}
