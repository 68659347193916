import { SlDefault } from '../_sl-default/sl-default';

export class SlTestimonials extends SlDefault {
    private options: JQuerySlickOptions = {
        slidesToShow: 1
    };

    constructor(el: HTMLElement) {
        super(el);
        this.init(this.options);
    }
}
