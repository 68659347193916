import { Tabs } from './common/tabs';
import { Accordion } from './common/accordion';
import { SlReports } from '../components/sliders/sl-reports/sl-reports';
import { SlTestimonials } from '../components/sliders/sl-testimonials/sl-testimonials';
import { SlPreview } from '../components/sliders/sl-preview/sl-preview';
import { SlViews } from '../components/sliders/sl-views/sl-views';
import { BtnMore } from '../components/buttons/btn-more/btn-more';
import { SlFeatures } from '../components/sliders/sl-features/sl-features';
import {DetectBrowser} from './common/helpers';

(() => {
    class App {
        public bootstrap() {
            const $body = $('body');

            const style =
                'padding: 5px 10px; background: linear-gradient(gold, orangered); font: 1.3rem Arial, sans-serif; color: white;';

            console.group('%c%s', style, 'App Initialization');
            console.log('App.init');
            console.groupEnd();

            const $bTabs = $body.find('.js-tabs');
            const $bAccordion = $body.find('.js-accordion');
            const $btnMore = $body.find('.js-scrollTo');

            const $slReports = $body.find('.sl-reports');
            const $slTestimonials = $body.find('.sl-testimonials');
            const $slPreview = $body.find('.sl-preview');
            const $slViews = $body.find('.sl-views');
            const $slFeatures = $body.find('.sl-features');

            $.get('images/sprite-svg.svg', data => {
                let svgWrap = document.createElement('noindex');
                svgWrap.innerHTML = new XMLSerializer().serializeToString(data.documentElement);
                document.body.insertBefore(svgWrap, document.body.childNodes[0]);

                svg4everybody();
            });

            new DetectBrowser();

            $bTabs.length && new Tabs($bTabs);
            $bAccordion.length && new Accordion($bAccordion);
            $btnMore.length && new BtnMore($btnMore);

            [
                { $el: $slPreview, module: SlPreview },
                { $el: $slReports, module: SlReports },
                { $el: $slTestimonials, module: SlTestimonials },
                { $el: $slViews, module: SlViews },
                { $el: $slFeatures, module: SlFeatures },
            ].map(
                _ =>
                    _.$el.length &&
                    _.$el.each(function() {
                        new _.module(this);
                    }),
            );
        }
    }

    $(() => {
        new App().bootstrap();
    });
})();
