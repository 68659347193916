import { Common } from '../../../js/common/common';

export class BtnMore {
    private $btn;

    constructor($btn) {
        this.$btn = $btn;
        this.init();
    }

    private scrollDown() {
        Common.scrollTo({ $el: $('body, html'), offset: $('.s-innovations').offset().top + 10, time: 300 });
    }

    private init() {
        $(document).on('click.toggleMobileMenu', '.js-scrollTo', this.scrollDown);
    }
}
