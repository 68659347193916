import { SlDefault } from './sl-default';
import { ModalDefault } from '../../modals/_modal-default/modal-default';
import { SlModal } from '../sl-modal/sl-modal';
import { filter } from 'rxjs/operators';

export class SlDefaultWithModal extends SlDefault {
    public readonly modalId: string;

    public modal = new ModalDefault();
    public slider;

    constructor(el: HTMLElement) {
        super(el);

        this.modalId = el.dataset.modalId;

        this.addEvents(el);
        this.createSubscription();
    }

    private openModal($el) {
        this.pause();
        this.slider.goToSlide(parseInt($el.attr('data-index'), 10));
        this.modal.open(this.modalId).then(() => this.play());
    }

    private addEvents(el: HTMLElement) {
        const that = this;

        $(el).on('click.openModal', '.js-openModal', function() {
            that.openModal.call(that, $(this));
        });
    }

    private createSubscription() {
        this.slider = new SlModal($(this.modalId).find('.sl-modal'));

        this.slider.subject
            .pipe(filter(({ name }) => name === 'beforeChange'))
            .subscribe(({ data }) => this.goToSlide(data));
    }
}
