import 'slick-carousel';
import { Subject } from 'rxjs/Subject';

const SLIDER_OPTIONS: JQuerySlickOptions = {
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    arrows: false,
    dots: true,
    dotsClass: 'sl-default__dots',
    customPaging: (slider, i) => '<button class="sl-default__dot"></button>',
    rows: 0,
};

export class SlDefault {
    private $el: JQuery;
    public readonly subject = new Subject<{ name: string; data: any }>();

    constructor(el: HTMLElement) {
        this.$el = $(el);
    }

    public init(options: JQuerySlickOptions) {
        const slick = this.$el.slick($.extend(true, {}, SLIDER_OPTIONS, options));

        this.$el.on('beforeChange', (event, slick, currentSlide, nextSlide) => {
            this.subject.next({ name: 'beforeChange', data: nextSlide });
        });

        return slick;
    }

    public destroy() {
        return this.$el.slick('unslick');
    }

    public reInit() {
        return this.$el.slick('reinit');
    }

    public setPosition() {
        return this.$el.slick('setPosition');
    }

    public goToSlide(index = 0) {
        this.$el.slick('slickGoTo', index, true);
    }

    public pause() {
        this.$el.slick('slickPause');
    }

    public play() {
        this.$el.slick('slickPlay');
    }
}
